import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from '../../layouts/main';
import { SplashScreen } from '../../components/ZoneComponents/loading-screen';

import { errorRoutes } from './error';
import { commonRoutes } from './common';
import CompactLayout from '../../layouts/compact';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('../../pages/LandingPage/LandingPage'));
const AboutPage = lazy(() => import('../../pages/AboutUs/AboutUs'));
const ProductsServicesPage = lazy(
    () => import('../../pages/ProductsServicesPage/ProductsServicesPage')
);
const ContactPage = lazy(() => import('../../pages/ContactPage/ContactPage'));
const ComingSoonPage = lazy(() => import('../../pages/coming-soon'));

export default function Router() {
    return useRoutes([
        {
            element: (
                <MainLayout>
                    <Suspense fallback={<SplashScreen />}>
                        <Outlet />
                    </Suspense>
                </MainLayout>
            ),
            children: [
                { element: <IndexPage />, index: true },
                { path: 'about-us', element: <AboutPage /> },
                { path: 'services', element: <ProductsServicesPage /> },
                { path: 'contact-us', element: <ContactPage /> }
            ]
        },

        // ...errorRoutes,

        // ...commonRoutes,

        { path: '*', element: <Navigate to="/" replace /> }
    ]);
}
