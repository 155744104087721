import Box from '@mui/material/Box';

import { usePathname } from '../../routes/hooks';

import { HEADER } from '../config-layout';

import Header from './header';
import Footer from './footer';
import React from 'react';
import { Navbar } from '../../components/Navbar/Navbar';

// ----------------------------------------------------------------------

const pathsOnDark = ['/'];

const spacingLayout = [...pathsOnDark, '/'];

type Props = {
    children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
    const pathname = usePathname();

    const actionPage = (arr: string[]) => arr.some((path) => pathname === path);

    React.useEffect(() => {
        console.log(actionPage(pathsOnDark));
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
            <Navbar headerOnDark={actionPage(pathsOnDark)} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1
                }}
            >
                {!actionPage(spacingLayout) && <Spacing />}

                {children}
            </Box>

            <Footer />
        </Box>
    );
}

// ----------------------------------------------------------------------

function Spacing() {
    return (
        <Box
            sx={{
                height: { xs: HEADER.H_MOBILE, md: HEADER.H_DESKTOP }
            }}
        />
    );
}
