import isEqual from 'lodash.isequal';
import { useMemo, useState, useCallback } from 'react';

import { useLocalStorage } from '../../../../hooks/use-local-storage';

import { SettingsValueProps } from '../types';

import { SettingsContext } from './settings-context';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
    defaultSettings: SettingsValueProps;
};

export function SettingsProvider({ children, defaultSettings }: Props) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

    const onUpdate = useCallback(
        (name: string, value: string | boolean) => {
            setSettings((prevState: SettingsValueProps) => ({
                ...prevState,
                [name]: value
            }));
        },
        [setSettings]
    );

    // Direction by lang
    const onChangeDirectionByLang = useCallback(
        (lang: string) => {
            onUpdate('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
        },
        [onUpdate]
    );

    // Reset
    const onReset = useCallback(() => {
        setSettings(defaultSettings);
    }, [defaultSettings, setSettings]);

    // Drawer
    const onToggleDrawer = useCallback(() => {
        setDrawerOpen((prev) => !prev);
    }, []);

    const onCloseDrawer = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const canReset = !isEqual(settings, defaultSettings);

    const memoizedValue = useMemo(
        () => ({
            ...settings,
            onUpdate,
            // Direction
            onChangeDirectionByLang,
            // Reset
            canReset,
            onReset,
            // Drawer
            open: drawerOpen,
            onToggle: onToggleDrawer,
            onClose: onCloseDrawer
        }),
        [
            onReset,
            onUpdate,
            settings,
            canReset,
            drawerOpen,
            onCloseDrawer,
            onToggleDrawer,
            onChangeDirectionByLang
        ]
    );

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
