import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

export const pageLinks = [
    {
        // isNew: true,
        order: '1',
        subheader: 'Common',
        // cover: '/assets/images/menu/menu_ecommerce.jpg',
        items: [
            { title: '404 Error', path: paths.page404 },
            { title: '500 Error', path: paths.page500 },
            { title: 'Maintenance', path: paths.maintenance },
            { title: 'ComingSoon', path: paths.comingsoon },
            { title: 'Pricing 01', path: paths.pricing01 },
            { title: 'Pricing 02', path: paths.pricing02 },
            { title: 'Payment', path: paths.payment },
            { title: 'Support', path: paths.support }
        ]
    }
];

export const navConfig = [
    { title: 'Home', path: '/' },
    { title: 'About Us', path: paths.aboutUs },
    { title: 'Contact', path: paths.contact },
    { title: 'Products & Services', path: paths.productsServices },
    { title: 'SimkoTec', path: paths.simkotec }
    // {
    //     title: 'Pages',
    //     path: '/pages',
    //     children: [pageLinks[0]]
    // }
];
