// ----------------------------------------------------------------------

export const paths = {
    // Common
    maintenance: '/maintenance',
    comingsoon: '/coming-soon',
    pricing01: '/pricing-01',
    pricing02: '/pricing-02',
    payment: '/payment',
    support: '/support',
    page404: '/404',
    page500: '/500',

    aboutUs: '/about-us',
    productsServices: '/services',
    industries: '/industries',
    contact: '/contact-us',
    simkotec: 'https://simkotec.com'
};
