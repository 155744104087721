import React, { useEffect } from 'react';
import { Stack, IconButton, List, Button, Drawer } from '@mui/material';
import { NavbarProps } from './types';

import { usePathname } from '../../routes/hooks';
import { useBoolean } from '../../hooks/use-boolean';

import { NAV } from '../../layouts/config-layout';
import NavList from '../../layouts/main/nav/mobile/nav-list';

import Iconify from '../ZoneComponents/iconify';
import Scrollbar from '../ZoneComponents/scrollbar/scrollbar';
import Logo from '../modules/Logo/Logo';

const NavbarMobile: React.FunctionComponent<NavbarProps> = ({ data }: NavbarProps) => {
    const pathname = usePathname();

    const mobileOpen = useBoolean();

    useEffect(() => {
        if (mobileOpen.value) {
            mobileOpen.onFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            <IconButton onClick={mobileOpen.onTrue} sx={{ ml: 1, color: 'inherit' }}>
                <Iconify icon="carbon:menu" />
            </IconButton>

            <Drawer
                open={mobileOpen.value}
                onClose={mobileOpen.onFalse}
                PaperProps={{
                    sx: {
                        pb: 5,
                        width: NAV.W_VERTICAL
                    }
                }}
            >
                <Scrollbar>
                    {/* TODO: Insert logo below */}
                    <Logo withColor={true} sx={{ mx: 2.5, my: 3 }} />

                    <List component="nav" disablePadding>
                        {data.map((link) => (
                            <NavList key={link.title} item={link} />
                        ))}
                    </List>
                </Scrollbar>
            </Drawer>
        </>
    );
};

export default NavbarMobile;
