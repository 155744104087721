import React from 'react';
import { AppBar, Container, Toolbar, useTheme, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useOffSetTop } from '../../hooks/use-off-set-top';
import { useResponsive } from '../../hooks/use-responsive';

import { HEADER } from '../../layouts/config-layout';
import HeaderShadow from '../../layouts/common/header-shadow';
import { bgBlur } from '../../theme/css';

import Logo from '../modules/Logo/Logo';
import Label from '../ZoneComponents/label';

import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';
import { navConfig } from './NavbarConfig';

interface NavbarProps {
    headerOnDark?: boolean;
}

export interface pagesObject {
    pageLabel: string;
    pageHref: string;
}

export const Navbar: React.FunctionComponent<NavbarProps> = (props: NavbarProps) => {
    const theme = useTheme();

    const offset = useOffSetTop();

    const mdUp = useResponsive('up', 'md');

    const { headerOnDark } = props;

    return (
        <AppBar>
            <Toolbar
                disableGutters
                sx={{
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter
                    }),
                    ...(headerOnDark && {
                        color: 'common.white'
                    }),
                    ...(offset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        color: 'text.primary',
                        height: {
                            md: HEADER.H_DESKTOP - 16
                        }
                    })
                }}
            >
                <Container
                    sx={{
                        height: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    {/* TODO: Insert Logo below */}
                    <Box sx={{ lineHeight: 0, position: 'relative' }}>
                        <Logo withColor={true} />
                    </Box>

                    {mdUp && <NavbarDesktop data={navConfig} />}

                    {!mdUp && <NavbarMobile data={navConfig} />}
                </Container>
            </Toolbar>

            {offset && <HeaderShadow />}
        </AppBar>
    );
};
