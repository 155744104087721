import Link from '@mui/material/Link';
import Masonry from '@mui/lab/Masonry';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Button, { buttonClasses } from '@mui/material/Button';

import Logo from '../../components/modules/Logo/Logo';
import Iconify from '../../components/ZoneComponents/iconify';
import { usePathname } from '../../routes/hooks';
import { useBoolean } from '../../hooks/use-boolean';
import { RouterLink } from '../../routes/components';
import { useResponsive } from '../../hooks/use-responsive';

import { NavListProps } from './nav/types';
import { pageLinks, navConfig } from './config-navigation';

// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
    flexShrink: 0,
    padding: '5px 12px',
    color: theme.palette.common.white,
    border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
    background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
    [`& .${buttonClasses.startIcon}`]: {
        marginLeft: 0
    }
}));

// ----------------------------------------------------------------------

export default function Footer() {
    const mdUp = useResponsive('up', 'md');

    const pathname = usePathname();

    const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

    const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

    const renderLists = mdUp ? desktopList : mobileList;

    const isHome = pathname === '/';

    const simpleFooter = (
        <Container sx={{ py: 8, textAlign: 'center' }}>
            <Logo withColor={true} single />

            <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
                © 2023. All rights reserved
            </Typography>
        </Container>
    );

    const mainFooter = (
        <>
            <Divider />

            <Container
                sx={{
                    overflow: 'hidden',
                    py: { xs: 8, md: 10 }
                }}
            >
                <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
                    <Grid xs={12} md={4}>
                        <Stack spacing={{ xs: 3, md: 5 }}>
                            <Stack alignItems="flex-start" spacing={3}>
                                <Logo withColor />

                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    YANNIS Global Corporation is a leading provider of total
                                    solutions and consulting for electrical and instrumentation EPC,
                                    including construction materials supply and SCM, as well as
                                    machinery and equipment installations for various industries.
                                    <br />
                                    <br />
                                    Meet our trusted affiliate{' '}
                                    <Link href="https://simkotec.com/">SIMKOTec</Link> for
                                    Engineering Works
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid xs={12} md={6}>
                        {mdUp ? (
                            <Masonry columns={4} spacing={2} defaultColumns={4} defaultSpacing={2}>
                                {renderLists.map((list) => (
                                    <ListDesktop key={list.subheader} list={list} />
                                ))}
                            </Masonry>
                        ) : (
                            <Stack spacing={1.5}>
                                {renderLists.map((list) => (
                                    <ListMobile key={list.subheader} list={list} />
                                ))}
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Container>

            <Divider />

            <Container>
                <Stack
                    spacing={2.5}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="space-between"
                    sx={{ py: 3, textAlign: 'center' }}
                >
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        © 2023. All rights reserved
                    </Typography>
                </Stack>
            </Container>
        </>
    );

    return <footer>{isHome ? simpleFooter : mainFooter}</footer>;
}

// ----------------------------------------------------------------------

export function ListDesktop({ list }: { list: NavListProps }) {
    const pathname = usePathname();

    return (
        <Stack spacing={1.5} alignItems="flex-start">
            <Typography variant="subtitle2">{list.subheader}</Typography>

            {list.items?.map((link) => {
                const active = pathname === link.path || pathname === `${link.path}/`;

                return (
                    <Link
                        component={RouterLink}
                        key={link.title}
                        href={link.path}
                        variant="caption"
                        sx={{
                            color: 'text.secondary',
                            '&:hover': {
                                color: 'text.primary'
                            },
                            ...(active && {
                                color: 'text.primary',
                                fontWeight: 'fontWeightSemiBold'
                            })
                        }}
                    >
                        {link.title}
                    </Link>
                );
            })}
        </Stack>
    );
}

// ----------------------------------------------------------------------

export function ListMobile({ list }: { list: NavListProps }) {
    const pathname = usePathname();

    const listExpand = useBoolean();

    return (
        <Stack spacing={1.5} alignItems="flex-start">
            <Typography
                variant="subtitle2"
                onClick={listExpand.onToggle}
                sx={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center'
                }}
            >
                {list.subheader}
                <Iconify
                    width={16}
                    icon={listExpand.value ? 'carbon:chevron-down' : 'carbon:chevron-right'}
                    sx={{ ml: 0.5 }}
                />
            </Typography>

            <Collapse in={listExpand.value} unmountOnExit sx={{ width: 1 }}>
                <Stack spacing={1.5} alignItems="flex-start">
                    {list.items?.map((link) => (
                        <Link
                            component={RouterLink}
                            key={link.title}
                            href={link.path}
                            variant="caption"
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'text.primary'
                                },
                                ...(pathname === `${link.path}/` && {
                                    color: 'text.primary',
                                    fontWeight: 'fontWeightSemiBold'
                                })
                            }}
                        >
                            {link.title}
                        </Link>
                    ))}
                </Stack>
            </Collapse>
        </Stack>
    );
}

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }: StackProps) {
    return (
        <Stack direction="row" flexWrap="wrap" spacing={2} {...other}>
            <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
                <Stack alignItems="flex-start">
                    <Typography variant="caption" sx={{ opacity: 0.72 }}>
                        Download on the
                    </Typography>

                    <Typography variant="h6" sx={{ mt: -0.5 }}>
                        Apple Store
                    </Typography>
                </Stack>
            </StyledAppStoreButton>

            <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
                <Stack alignItems="flex-start">
                    <Typography variant="caption" sx={{ opacity: 0.72 }}>
                        Download from
                    </Typography>

                    <Typography variant="h6" sx={{ mt: -0.5 }}>
                        Google Play
                    </Typography>
                </Stack>
            </StyledAppStoreButton>
        </Stack>
    );
}
