import React from 'react';
import ThemeProvider from '../src/theme';
import { FormspreeProvider } from '@formspree/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalizationProvider from './locales/localization-provider';
import { SettingsDrawer, SettingsProvider } from './components/ZoneComponents/settings';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import MotionLazy from './components/ZoneComponents/animate/motion-lazy';
import Router from './routes/sections';
import './global.css';
import ProgressBar from './components/ZoneComponents/progress-bar';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
    // const THEME = createTheme({
    //     typography: {
    //         fontFamily: 'Poppins, sans-serif'
    //     }
    // });
    useScrollToTop();

    return (
        <LocalizationProvider>
            <SettingsProvider
                defaultSettings={{
                    themeMode: 'light', // 'light' | 'dark'
                    themeDirection: 'ltr', //  'rtl' | 'ltr'
                    themeColorPresets: 'default' // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                }}
            >
                <FormspreeProvider project={process.env.REACT_APP_FORMSPREE_PROJECT_ID}>
                    <ChakraProvider>
                        <ThemeProvider>
                            <MotionLazy>
                                <ProgressBar />
                                <SettingsDrawer />
                                <Router />
                            </MotionLazy>
                        </ThemeProvider>
                    </ChakraProvider>
                </FormspreeProvider>
            </SettingsProvider>
        </LocalizationProvider>
    );
}

export default App;
