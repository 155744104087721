import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

export const pageLinks = [
    {
        // isNew: true,
        order: '1',
        subheader: 'Pages',
        // cover: '/assets/images/menu/menu_ecommerce.jpg',
        items: [
            { title: 'About Us', path: paths.aboutUs },
            { title: 'Contact', path: paths.contact },
            { title: 'Products & Services', path: paths.productsServices },
            { title: 'Simkotec', path: paths.simkotec }
        ]
    }
];

export const navConfig = [
    { title: 'Home', path: '/', children: [] },
    { title: 'About Us', path: paths.aboutUs, children: [] },
    { title: 'Contact', path: paths.contact, children: [] },
    { title: 'Products & Services', path: paths.productsServices, children: [] },
    { title: 'SimkoTec', path: paths.simkotec, children: [] }
    // {
    //     title: 'Pages',
    //     path: '/pages',
    //     children: [pageLinks[0]]
    // }
];
