import React from 'react';
import { NavbarProps } from './types';
import NavList from '../../layouts/main/nav/desktop/nav-list';
import Stack from '@mui/material/Stack';

const NavbarDesktop: React.FunctionComponent<NavbarProps> = ({ data, sx }: NavbarProps) => {
    return (
        <Stack
            component="nav"
            direction="row"
            spacing={5}
            sx={{
                height: 1,
                ...sx
            }}
        >
            {data.map((link) => (
                <NavList key={link.title} item={link} />
            ))}
        </Stack>
    );
};

export default NavbarDesktop;
